package com.ecosave.watch.portal.components.esg

import com.ecosave.watch.portal.components.common.AlertNotifications
import com.ecosave.watch.portal.components.common.DialogSpinner
import com.ecosave.watch.portal.helpers.Constants
import com.ecosave.watch.portal.helpers.common.ApiCallStatus
import com.ecosave.watch.portal.helpers.common.NotificationStatus
import com.ecosave.watch.portal.helpers.esg.EsgPages
import com.ecosave.watch.portal.helpers.esg.TOCSectionsEnum
import com.ecosave.watch.portal.helpers.mainScope
import com.ecosave.watch.portal.models.common.NotificationState
import com.ecosave.watch.portal.services.esg.previewGriStandardReport
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Button
import mui.material.ButtonVariant
import mui.material.Link
import mui.material.Tooltip
import mui.system.sx
import react.FC
import react.Props
import react.StateSetter
import react.create
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.ol
import react.dom.html.ReactHTML.p
import react.useState
import web.cssom.Display
import web.cssom.JustifyContent
import web.cssom.px
import web.window.WindowTarget

external interface NavigationComponentProps : Props {
    var visiblePage: EsgPages
    var setVisiblePage: StateSetter<EsgPages>
    var griStandard: TOCSectionsEnum?
    var reportFileName: String
}

val NavigationComponent = FC<NavigationComponentProps> { props ->

    var isDownloading by useState(false)
    val (notificationState, setNotificationState) = useState(NotificationState())

    Box {
        sx {
            display = Display.flex
            gap = 10.px
            justifyContent = JustifyContent.flexEnd
            marginBottom = 20.px
        }
        if (props.visiblePage == EsgPages.TABLE_OF_CONTENTS) {
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    props.setVisiblePage(EsgPages.SELECT_GRI_STANDARDS)
                }
                +"Include/Exclude GRI Standards"
            }
        }
        if (props.visiblePage == EsgPages.SELECTED_GRI_STANDARD_FORM) {
            Tooltip {
                arrow = true
                title = div.create {
                    p {
                        +"Best Practices Guideline For Sustainability Reporting."
                    }
                    ol {
                        li {
                            +"Key concepts (GRI 1, section 2, page 11)."
                        }
                        li {
                            +"Requirements for reporting in accordance with the GRI Standards (GRI 1, section 3, page 14)."
                        }
                        li {
                            +"Reporting Principles and their requirements (GRI 1, section 4, page 23)."
                        }
                        li {
                            +"Additional recommendations for reporting (GRI 1, section 5, page 28)."
                        }
                    }
                    p{
                        +"Refer to current GRI Standard in provided document."
                    }
                }
                Link {
                    target = WindowTarget._blank
                    href = "https://ecosavewatch-gri-standards.s3.us-east-2.amazonaws.com/2023+Consolidated+Set+of+the+GRI+Standards.pdf"
                    Button {
                        variant = ButtonVariant.contained
                        +"GRI Guidelines"
                    }
                }
            }
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    props.setVisiblePage(EsgPages.TABLE_OF_CONTENTS)
                }
                +"Table Of Contents"
            }
            Button {
                variant = ButtonVariant.contained
                onClick = {
                    mainScope.launch {
                        isDownloading = true
                        val status = previewGriStandardReport(props.reportFileName, props.griStandard!!)
                        if (status == ApiCallStatus.FAILURE) {
                            setNotificationState(
                                notificationState.copy(
                                    status = NotificationStatus.ERROR,
                                    message = Constants.NOTIFICATION_ERROR_MESSAGE,
                                    visible = true
                                )
                            )
                        }
                        isDownloading = false
                    }
                }
                +"Preview"
            }
        }
        Button {
            variant = ButtonVariant.contained
            onClick = {
                props.setVisiblePage(EsgPages.ESG_REPORTS)
            }
            +"All Reports"
        }
    }
    DialogSpinner {
        open = isDownloading
        message = "Generating your report..."
    }
    AlertNotifications {
        open = notificationState.visible
        status = notificationState.status
        message = notificationState.message
        closeNotification = {
            setNotificationState(
                notificationState.copy(
                    visible = false
                )
            )
        }
    }
}
