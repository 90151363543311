package com.ecosave.watch.portal.models.esg

import com.ecosave.watch.portal.helpers.esg.DisclosureOmissionReason
import com.ecosave.watch.portal.helpers.esg.GriDisclosureTitles
import com.ecosave.watch.portal.helpers.esg.EsgSection
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.JsonPrimitive

@Serializable
data class ReportFinancialYear(
    val financialYear: Int? = null,
    val calendarDateStart: String = "",
    val calendarDateEnd: String = "",
)

@Serializable
data class ModifiedBy(
    val firstName: String,
    val lastName: String,
    val companyId: Int,
    val companyName: String,
    val jobTitle: String,
    val userRole: String,
    val emailAddress: String,
)

@Serializable
data class EsgReportState(
    var reportFileName: String = "",
    var reportTitle: String = "",
    val report: EsgReportData? = EsgReportData(),
    val projectName: String = "",
    var companyName: String = "",
    val reportFinancialYear: ReportFinancialYear = ReportFinancialYear(),
    val modifiedBy: ModifiedBy? = null,
    val lastModifiedTime: String = "",
    var mainTableOfContents: MainTableOfContents? = null,
    var omittedDisclosuresList: List<GriDisclosureTitles> = listOf(),
    var esgSections: MutableList<EsgSection> = mutableListOf(),
    val lastModifiedBy: String = "",
)

@Serializable
data class EsgReportData(
    val activitiesAndWorkers: ActivitiesAndWorkersState? = ActivitiesAndWorkersState(),
    val antiCompetitiveBehavior: AntiCompetitiveBehaviorState? = AntiCompetitiveBehaviorState(),
    val antiCorruption: AntiCorruptionState? = AntiCorruptionState(),
    val biodiversity: BiodiversityState? = BiodiversityState(),
    val childLabor: ChildLaborState? = ChildLaborState(),
    val customerHealthAndSafety: CustomerHealthAndSafetyState? = CustomerHealthAndSafetyState(),
    val customerPrivacy: CustomerPrivacyState? = CustomerPrivacyState(),
    val disclosuresOnMaterialTopics: DisclosuresOnMaterialTopicsState? = DisclosuresOnMaterialTopicsState(),
    val diversityAndEqualOpportunity: DiversityAndEqualOpportunityState? = DiversityAndEqualOpportunityState(),
    val economicPerformance: EconomicPerformanceState? = EconomicPerformanceState(),
    val emissions: EmissionsState? = EmissionsState(),
    val employment: EmploymentState? = EmploymentState(),
    val energy: EnergyState? = EnergyState(),
    val forcedOrCompulsoryLabor: ForcedOrCompulsoryLaborState? = ForcedOrCompulsoryLaborState(),
    val freedomOfAssociationAndCollectiveBargaining: FreedomOfAssociationAndCollectiveBargainingState? = FreedomOfAssociationAndCollectiveBargainingState(),
    val governance: GovernanceState? = GovernanceState(),
    val humanRightsAssessment: HumanRightsAssessmentState? = HumanRightsAssessmentState(),
    val indirectEconomicImpacts: IndirectEconomicImpactsState? = IndirectEconomicImpactsState(),
    val laborOrManagementRelations: LaborOrManagementRelationsState? = LaborOrManagementRelationsState(),
    val localCommunity: LocalCommunityState? = LocalCommunityState(),
    val marketingAndLabeling: MarketingAndLabelingState? = MarketingAndLabelingState(),
    val marketPresence: MarketPresenceState? = MarketPresenceState(),
    val materials: MaterialsState? = MaterialsState(),
    val nonDiscrimination: NonDiscriminationState? = NonDiscriminationState(),
    val occupationalHealthAndSafety: OccupationalHealthAndSafetyState? = OccupationalHealthAndSafetyState(),
    val procurementPractices: ProcurementPracticesState? = ProcurementPracticesState(),
    val publicPolicy: PublicPolicyState? = PublicPolicyState(),
    val rightsOfIndigenousPeoples: RightsOfIndigenousPeoplesState? = RightsOfIndigenousPeoplesState(),
    val securityPractices: SecurityPracticesState? = SecurityPracticesState(),
    val socioeconomicCompliance: SocioeconomicComplianceState? = SocioeconomicComplianceState(),
    val stakeholderEngagement: StakeholderEngagementState? = StakeholderEngagementState(),
    val strategyPoliciesPractices: StrategyPoliciesPracticesState? = StrategyPoliciesPracticesState(),
    val supplierEnvironmentalAssessment: SupplierEnvironmentalAssessmentState? = SupplierEnvironmentalAssessmentState(),
    val supplierSocialAssessment: SupplierSocialAssessmentState? = SupplierSocialAssessmentState(),
    val tax: TaxState? = TaxState(),
    val theOrgAndItsRepPractices: TheOrgAndItsRepPracticesState? = TheOrgAndItsRepPracticesState(),
    val trainingAndEducation: TrainingAndEducationState? = TrainingAndEducationState(),
    val waste: WasteState? = WasteState(),
    val waterAndEffluents: WaterAndEffluentsState? = WaterAndEffluentsState(),
    var reportCoverInfo: ReportCoverInfoState? = null,
)

@Serializable
data class MainTitleStatusTracker(
    val esgSection: EsgSection,
    var percentCompleted: Int,
    val modifiedBy: ModifiedBy? = null,
)

@Serializable
data class SubTitleStatusTracker(
    val subTitle: String? = null,
    val subSection: String? = null,
    var percentCompleted: Int = 0,
    var modifiedBy: ModifiedBy? = null,
    val modifiedDateTime: String? = null,
)

@Serializable
data class ItemStatusTracker(
    val disclosure: GriDisclosureTitles,
    var completed: Boolean,
    val omittedDetail: OmittedDetail?,
)

@Serializable
data class OmittedDetail(
    val reason: DisclosureOmissionReason?,
    val explanation: String?,
)

@Serializable
data class OmitOrIncludeDisclosureRequest(
    var reportFileName: String,
    var disclosure: GriDisclosureTitles,
    var omittedDetail: OmittedDetail?,
)

@Serializable
data class OmitOrIncludeDisclosureResponse(
    val mainTableOfContents: MainTableOfContents,
    val omittedDisclosuresList: List<GriDisclosureTitles>,
)

@Serializable
data class EnrichedSubContent(
    val subTitleStatusTracker: SubTitleStatusTracker? = SubTitleStatusTracker(),
    val itemStatusTrackerList: List<ItemStatusTracker>,
)

@Serializable
data class EnrichedTableOfContent(
    val mainTitleStatusTracker: MainTitleStatusTracker,
    val enrichedSubContents: List<EnrichedSubContent>,
)

@Serializable
data class MainTableOfContents(
    var totalPercentCompleted: Int,
    val enrichedTableOfContents: List<EnrichedTableOfContent>?,
)

@Serializable
data class IncludeOrExcludeGriStandardsRequest(
    val reportFileName: String,
    val selectedEsgSections: List<EsgSection>,
)

data class CurrentInputState(
    val parentPath: String? = null,
    val childPath: String,
    val index: Int? = null,
    val value: JsonPrimitive? = null,
    val objectName: String,
    val reportName: String,
)

@Serializable
data class EsgReportCoverPage(
    val esgReportCoverImageUrl: String? = null
)